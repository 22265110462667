import { fromPaymentMethod } from '../reducers';

/**
 * An array of payment method actions.
 * Each action object contains a name and a corresponding reducer action.
 * @type {{ name: string, action: Function }[]}
 * @property {string} name - The name of the action.
 * @property {Function} action - The reducer function associated with the action.
 */
export const PaymentMethodActions = [
    { name: '[Payment Method] Load', action: fromPaymentMethod.reducer.load },
    { name: '[Payment Method] Load recent', action: fromPaymentMethod.reducer.loadRecent },
    { name: '[Payment Method] Increase page', action: fromPaymentMethod.reducer.increasePage },
    { name: '[Payment Method] Reset page', action: fromPaymentMethod.reducer.resetPage },
    { name: '[Payment Method] Toggle see all', action: fromPaymentMethod.reducer.toggleSeeAll },
    { name: '[Payment Method] Select payment method', action: fromPaymentMethod.reducer.selectPaymentMethod }
];
