import { Container } from 'aurelia-framework';
import { AppState, PaymentMethodState } from 'resources/helpers/types';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';

export const paymentMethodFeatureKey = 'paymentMethod';

/**
 * @type {PaymentMethodState}
 */
export const initialState = {
    paymentMethods: [],
    recentPaymentMethods: [],
    page: 1,
    selectedPaymentMethodId: null,
    seeAll: false
};

const paymentMethodWebsiteService = Container.instance.get(PaymentMethodWebsiteService);

export const reducer = {
    /**
     * Loads payment methods into the state.
     * @param {AppState} state - The current state of the application.
     * @returns {Promise<AppState>} The new state with loaded payment methods.
     * @external Promise
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise}
     */
    load: async(state) => {
        if (state.paymentMethod.paymentMethods.length) return;
        const paymentMethods = await paymentMethodWebsiteService.getByWebsite();

        return {
            ...state,
            [paymentMethodFeatureKey]: {
                ...state.paymentMethod,
                paymentMethods
            }
        };
    },
    /**
     * Loads recent payment methods into the state.
     * @param {AppState} state
     * @returns {Promise<AppState>} The new state with loaded recent payment methods.
     * @external Promise
     */
    loadRecent: async(state) => {
        if (state.paymentMethod.recentPaymentMethods.length) return;
        const recentPaymentMethods = await paymentMethodWebsiteService.getRecentPaymentMethodByUserId();

        return {
            ...state,
            [paymentMethodFeatureKey]: {
                ...state.paymentMethod,
                recentPaymentMethods
            }
        };
    },
    /**
     * Increases the current page of payment methods.
     * @param {AppState} state
     * @returns {AppState} The new state with the page increased
     */
    increasePage: state => {
        const { page, paymentMethods } = state.paymentMethod;
        const pageCount = Math.ceil(paymentMethods.length / 11);

        return {
            ...state,
            [paymentMethodFeatureKey]: {
                ...state.paymentMethod,
                page: page < pageCount ? page + 1 : page
            }
        };
    },
    /**
     * Resets the current page of payment methods to the first page.
     * @param {AppState} state
     * @returns {AppState} The new state with the page reset
     */
    resetPage: state => {
        return {
            ...state,
            [paymentMethodFeatureKey]: {
                ...state.paymentMethod,
                page: 1
            }
        };
    },
    /**
     *
     * @param {AppState} state
     * @param {number} paymentMethodId
     * @returns {AppState} The new state with the page reset
     */
    selectPaymentMethod: (state, paymentMethodId) => {
        return {
            ...state,
            [paymentMethodFeatureKey]: {
                ...state.paymentMethod,
                selectedPaymentMethodId: paymentMethodId
            }
        };
    },
    /**
     *
     * @param {AppState} state
     * @param {boolean | null} seeAll
     * @returns {AppState} The new state with the seeAll property toggled
     */
    toggleSeeAll: (state, seeAll = null) => {
        return {
            ...state,
            [paymentMethodFeatureKey]: {
                ...state.paymentMethod,
                seeAll: seeAll === null ? !state.paymentMethod.seeAll : seeAll
            }
        };
    }
};
